import React from 'react';
import { IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { RouteComponentProps } from 'react-router-dom';
import Button from '../atoms/Button';
import ContentWrapper from '../molecules/ContentWrapper';
import useFetch from '../../hooks/useFetch';

interface Entry {
  id: string;
  title: string;
  locationOther: string;
  image?: {
    srcUrl: string;
    alt?: string;
  }[];
}

const Events: React.FC<RouteComponentProps> = () => {

  const {
    data,
    loading,
    error,
    isConnected,
    refetch,
  } = useFetch<{ entries: Entry[] }>(`${process.env.REACT_APP_API_DOMAIN}/api/v2/app-events`);

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  return (
    <IonPage>
      <IonHeader translucent className="ion-no-border">
        <IonToolbar
          style={{ '--background': 'var(--ion-tab-bar-background-color)' }}
          className="bg-opacity-50"
        >
          <IonTitle>Events</IonTitle>
        </IonToolbar>
      </IonHeader>
      <ContentWrapper
        handleRefresh={handleRefresh}
        isConnected={isConnected}
        error={error}
        loading={loading}
      >
        {data && (
          <>
            {data.entries.map((event: Entry) => (
              <React.Fragment key={event.id}>
                {event.image ? (
                  <Button
                    to={`/events/${event.id}`}
                    text={event.title}
                    tag={event.locationOther}
                    image={event.image[0]}
                    AspectVideo
                  />
                ) : (
                  <Button
                    to={`/events/${event.id}`}
                    text={event.title}
                    tag={event.locationOther}
                    fallback="CalendarLines"
                    AspectVideo
                  />
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </ContentWrapper>
    </IonPage>
  );
};

export default Events;
