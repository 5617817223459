import React from "react";
import useFetch from "../../../hooks/useFetch";
import DateString from "../../utilities/DateString";
import TimeString from "../../utilities/TimeString";

export interface TimeListProps {
  eventId: string;
  dataKey: "registration" | "exhibits"; // restricts dataKey to either "registration" or "exhibitHall"
  headerText: string;
  refetch?: ()=>void;
}

const TimeList: React.FC<TimeListProps> = ({ eventId, dataKey, headerText, refetch }) => {
  const { data } = useFetch<{ entry: { [key: string]: any[] } }>(
    `${process.env.REACT_APP_API_DOMAIN}/api/v2/app-event?id=${eventId}`
  );

  // console.log(data?.entry[dataKey]);
  let currentDate = "";

  return (
    <>
      {data && data.entry[dataKey] && (
        <div className="my-12">
          <h3 className="text-lg font-semibold tracking-widest mb-2 text-primary uppercase">
            {headerText}
          </h3>
          {data.entry[dataKey].map((item) => {
            const { id, startDate, startTime, endTime } = item;

            if (currentDate != startDate){
              currentDate = startDate
              return (
                  <div key={id} className="">
                    <p className="font-semibold tracking-wide mt-4">
                      {DateString(startDate, "short")}
                    </p>
                    <p className="text-sm uppercase tracking-wider font-medium">
                      {TimeString(startTime, endTime)}
                    </p>
                  </div>
              );
            } else {
              return (
                  <div key={id} className="">
                    <p className="text-sm uppercase tracking-wider font-medium">
                      {TimeString(startTime, endTime)}
                    </p>
                  </div>
              )
            }
          })}
        </div>
      )}
    </>
  );
};

export default TimeList;
