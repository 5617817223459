import React from "react";
import { RouteComponentProps } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import Button from "../../atoms/Button";
import Headline from "../../atoms/Headline";
import ContentWrapper from "../../molecules/ContentWrapper";
import * as icons from "../../icons";

interface MatchParams {
  eventId: string;
}

interface ProfilePhoto {
  url?: string;
}

interface Person {
  id: string;
  fullName: string;
  title: string;
  profilePhoto: ProfilePhoto[];
}

interface Entry {
  id: string;
  persons?: Person[];
  speakers?: Person[];
  worship?: Person[];
}


interface Props extends RouteComponentProps<MatchParams> {
  headlineTitle: string;
  icon: keyof typeof icons;
  getPersons: (data: Entry) => Person[];
}

const PeopleList: React.FC<Props> = ({ headlineTitle, icon, getPersons, match }) => {
  const { eventId } = match.params;
  const { data, loading, error, refetch, isConnected } = useFetch<{ entry: Entry }>(
    `${process.env.REACT_APP_API_DOMAIN}/api/v2/app-event?id=${eventId}`
  );

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  return (
    <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
      {data && (
        <>
          <Headline title={headlineTitle} icon={icon} />
          {getPersons(data.entry).map((person: Person) => (
            <Button
              key={person.id}
              to={`/events/${eventId}/details/person/${person.id}`}
              image={{ srcUrl: person.profilePhoto[0]?.url || "", alt: person.fullName }}
              text={person.title}
              fallback='UserSolid'
            />
          ))}
        </>
      )}
    </ContentWrapper>
  );
};

export default PeopleList;
