import React from "react";
import { RouteComponentProps } from "react-router-dom";
import PeopleList from "../organisms/PeopleList";
import {IonPage} from "@ionic/react";
import EventHeader from "./EventHeader";

interface MatchParams {
  eventId: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

const Worship: React.FC<Props> = ({match, ...props}) => {
  const { eventId } = match.params;
return (
  <IonPage>
    <EventHeader eventId={eventId}/>
    <PeopleList
      {...props}
      match={match}
      headlineTitle="Worship Leaders"
      icon="MicrophoneStand"
      getPersons={(entry) => entry.worship || []}
    />
  </IonPage>

)}

export default Worship;
