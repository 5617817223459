import React from "react";
import useFetch from "../../hooks/useFetch";
import Headline from "../atoms/Headline";
import Subhead from "../atoms/Subhead";
import TextBlock from "../atoms/TextBlock";
import ContentWrapper from "../molecules/ContentWrapper";
import DateString from "../utilities/DateString";
import TimeString from "../utilities/TimeString";
import TransparentBg from "../molecules/TransparentBg";
import {IonPage} from "@ionic/react";
import EventHeader from "./EventHeader";
import {RouteComponentProps} from "react-router-dom";

interface Entry {
  id: string;
  title: string;
  venue?: string;
  startDate?: string;
  startTime?: string;
  endTime?: string;
  descriptionRichText?: string;
}



interface MatchParams {
  entryId: string;
  eventId: string;
}

interface Props extends RouteComponentProps<MatchParams> {}


const Meal: React.FC<Props> = ({ match }) => {
  const { entryId, eventId } = match.params;
  const { data, loading, error, refetch, isConnected } = useFetch<{ entry: Entry }>(
    `${process.env.REACT_APP_API_DOMAIN}/api/v2/app-meal?id=${entryId}`
  );

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  const html = { __html: data?.entry?.descriptionRichText || '' };

  return (
    <IonPage>
      <EventHeader eventId={eventId}/>
      <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
        {data && (
          <>
            <Headline title={data.entry.title} icon="Utensils" tag={data.entry.venue} />
            <Subhead title={DateString(data.entry.startDate || '')} tag={TimeString(data.entry.startTime || '', data.entry.endTime || '')} />

            {data.entry.descriptionRichText && (
              <TransparentBg>
                <TextBlock text={html} />
              </TransparentBg>
            )}
          </>
        )}
      </ContentWrapper>
    </IonPage>

  );
};

export default Meal;
