import {IonButton, IonButtons, IonHeader, IonToolbar} from "@ionic/react";
import TopNavButton from "../atoms/TopNavButton";

interface EventHeaderProps {
  eventId: string;
}

export default function EventHeader({eventId}: EventHeaderProps){
  return(
    <IonHeader className="ion-no-border border-b dark:border-neutral-800 bg-white">
    <IonToolbar style={{ "--background": "var(--ion-tab-bar-background-color)" }} className="bg-opacity-50">
      <IonButtons slot="start">
        <IonButton>
          <TopNavButton icon="ChevronLeft" />
        </IonButton>
      </IonButtons>
      <IonButtons slot="end">
        <IonButton>
          <TopNavButton icon="CalendarLines" route={`/events/${eventId}`} />
        </IonButton>
      </IonButtons>
    </IonToolbar>
  </IonHeader>
  )
}
