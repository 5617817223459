import React from "react";
import useFetch from '../../hooks/useFetch';
import Headline from "../atoms/Headline";
import TextBlock from "../atoms/TextBlock";
import ContentWrapper from "../molecules/ContentWrapper";
import TransparentBg from "../molecules/TransparentBg";
import {IonPage} from "@ionic/react";
import EventHeader from "./EventHeader";

interface Entry {
  id: string;
  title: string;
  fullName?: string;
  descriptionRichText?: string;
  profilePhoto?: { url: string };
}

interface Props {
  match: {
    params: {
      entryId: string;
      eventId: string;
    };
  };
}

const Person: React.FC<Props> = ({ match }) => {
  const { entryId, eventId } = match.params;

  const { data, loading, error, refetch, isConnected } = useFetch<{ entry: Entry }>(
    `${process.env.REACT_APP_API_DOMAIN}/api/v2/app-person?id=${entryId}`
  );

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  const html = { __html: data?.entry?.descriptionRichText || "" };

  const imageUrl = data?.entry?.profilePhoto?.url || null;

  return (
    <IonPage>
      <EventHeader eventId={eventId}/>
      <ContentWrapper
        handleRefresh={handleRefresh}
        isConnected={isConnected}
        error={error}
        loading={loading}
      >
        {data && (
          <>
            {imageUrl && (
              <img
                src={imageUrl}
                alt="Profile"
                style={{ maxWidth: '100%' }}  // Ensure the image is fully visible
                className="box overflow-hidden rounded-lg mb-8"
              />
            )}

            <Headline title={data.entry.fullName ?? data.entry.title} />

            <TransparentBg>
              <TextBlock text={html} />
            </TransparentBg>
          </>
        )}
      </ContentWrapper>
    </IonPage>

  );
};

export default Person;
