import React, { useEffect, useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { IonPage, IonButton, IonButtons, IonHeader, IonToolbar } from "@ionic/react";
import Headline from "../atoms/Headline";
import TopNavButton from "../atoms/TopNavButton";
import ArticleWrapper from "../molecules/ArticleWrapper";
import ArticleContent from "../organisms/articleContent";
import useFetch from '../../hooks/useFetch';

interface MatchParams {
  articleId: string;
}

interface Entry {
  id: string;
  title: string;
  articleAuthor: string;
  teaser?: string;
  featuredImage?: {
    url: string;
  };
  articleBuilderV1: Array<
    | {
    typeHandle: 'text';
    id: string;
    paragraphText: string;
  }
    | {
    typeHandle: 'articleImage';
    id: string;
    image: {
      url: string;
    }[];
  }
  >;
}

interface ArticleProps extends RouteComponentProps<MatchParams> {}

const Article: React.FC<ArticleProps> = ({ match }) => {
  const entryId = match.params.articleId;
  const { data, loading, error, refetch, isConnected } = useFetch<{ entry: Entry }>(
    `${process.env.REACT_APP_API_DOMAIN}/api/v2/app-article?id=${entryId}`
  );

  const handleRefresh = async (article: CustomEvent) => {
    await refetch();
    article.detail.complete();
  };

  const style = useMemo(() => {
    if (data && data.entry) {
      let color = "#00b140";
      const colorR = color.slice(1, 3);
      const colorG = color.slice(3, 5);
      const colorB = color.slice(5, 7);

      return {
        '--color-primary': `${parseInt(colorR, 16)} ${parseInt(colorG, 16)} ${parseInt(colorB, 16)}`
      };
    }
    return {};
  }, [data]);

  useEffect(() => {
    if (style['--color-primary']) {
      document.documentElement.style.setProperty('--color-primary', style['--color-primary']);
    }
  }, [style]);

  return (
    <IonPage>
      <IonHeader translucent className="ion-no-border">
        <IonToolbar style={{ "--background": "var(--ion-tab-bar-background-color)" }} className="bg-opacity-50">
          <IonButtons slot="start">
            <IonButton>
              <TopNavButton icon="ChevronLeft" route='/news' />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <ArticleWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
        {data && (
          <>
            {data.entry?.featuredImage && (
              <div className="bg-neutral-500 overflow-hidden -mx-6 -mt-6 mb-5 md:rounded-lg aspect-video bg-opacity-20">
                <img
                  src={data.entry.featuredImage.url}
                  className="block h-full w-full aspect-video"
                  alt=""
                />
              </div>
            )}
            {data.entry?.title && data.entry?.articleBuilderV1 && (
              <>
                <Headline title={data.entry.title} tag={data.entry.articleAuthor} />
                <ArticleContent articleId={entryId} blocks={data.entry.articleBuilderV1} />
              </>
            )}
          </>
        )}
      </ArticleWrapper>
    </IonPage>
  );
};

export default Article;
