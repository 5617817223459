import { useState, useEffect, useCallback } from 'react';
import { useNetworkStatus } from "./useNetworkStatus";

interface UseFetchResult<T> {
  data: T | null;
  loading: boolean;
  error: Error | null;
  isConnected: boolean;
  refetch: () => Promise<void>;
}

const useFetch = <T,>(url: string): UseFetchResult<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const isConnected = useNetworkStatus();

  const fetchData = useCallback(
    async (useCache = true) => {
      setLoading(true);
      try {
        const cacheKey = `cache_${url}`;

        if (useCache) {
          const cachedData = localStorage.getItem(cacheKey);

          if (cachedData) {
            const { timestamp, data: cachedJsonData } = JSON.parse(cachedData);
            const currentTime = Date.now();
            const expirationTime = 48 * 60 * 60 * 1000; // 48 hours in milliseconds

            if (currentTime - timestamp < expirationTime) {
              setData(cachedJsonData);
              setLoading(false);
              return;
            }
          }
        }

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const jsonData = await response.json();
        setData(jsonData);
        setError(null);

        localStorage.setItem(cacheKey, JSON.stringify({ timestamp: Date.now(), data: jsonData }));
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }, [url]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const refetch = useCallback(() => fetchData(false), [fetchData]);

  return { data, loading, isConnected, error, refetch };
};

export default useFetch;
