import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Button from "../atoms/Button";
import Headline from "../atoms/Headline";
import Subhead from "../atoms/Subhead";
import ContentWrapper from "../molecules/ContentWrapper";
import DateString from "../utilities/DateString";
import timeString from "../utilities/TimeString";
import * as icons from "../icons";
import TransparentBg from "../molecules/TransparentBg";
import TextBlock from "../atoms/TextBlock";
import {IonPage} from "@ionic/react";
import EventHeader from "./EventHeader";

interface Entry {
  id: string;
  title: string;
  startTime: string;
  endTime: string;
  descriptionRichText?: string | null;
  icon?: keyof typeof icons;
  children?: Array<Child>;
  parent?: Parent;
}

interface Child {
  id: string;
  title: string;
  typeHandle: string;
  shortname?: string;
  venue?: string;
}

interface Parent {
  id: string;
  title: string;
  startDate: string;
}

interface MatchParams {
  entryId: string;
  eventId: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

const Activity: React.FC<Props> = ({ match }) => {
  const { entryId, eventId } = match.params;
  const { data, loading, error, refetch, isConnected } = useFetch<{ entry: Entry }>(
    `${process.env.REACT_APP_API_DOMAIN}/api/v2/app-activity?id=${entryId}`
  );

  const { entry } = data ?? {};

  useEffect(() => {
    refetch();
  }, [entryId, refetch]);

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  const html = { __html: entry?.descriptionRichText ?? "" };
  return (
    <IonPage>
      <EventHeader eventId={eventId}/>
      <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
        {data?.entry && (
          <>
            <Headline title={data?.entry.title} icon={data?.entry.icon} />
            <Subhead
              title={data?.entry.parent ? DateString(data?.entry.parent.startDate) : ""}
              tag={timeString(data?.entry.startTime, data?.entry.endTime)}
            />

            {data?.entry.descriptionRichText && (
              <TransparentBg>
                <TextBlock text={html} />
              </TransparentBg>
            )}

            {data?.entry.children?.map((child: Child) => {
              const childRoute = `/events/${eventId}/details/${child.typeHandle}/${child.id}`;
              return (
                <Button key={child.id} to={childRoute} text={child.title} tag={child.typeHandle === "workshop" ? child.shortname : child.venue} />
              );
            })}
          </>
        )}
      </ContentWrapper>
    </IonPage>

  );
};

export default Activity;
