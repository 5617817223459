import React from "react";
import TimeList from "../../molecules/TimeList/TimeList";

type DynamicInformationProps = {
  option: string | undefined;
  eventId: string;
  refetch?: ()=> void;
};

const DynamicInformation: React.FC<DynamicInformationProps> = ({ option, eventId, refetch }) => {
  switch (option) {
    case "registrationTimes":
      return (
        <TimeList eventId={eventId} dataKey="registration" headerText="Registration Hours" refetch={refetch} />
      );
    case "exhibitHallTimes":
      return (
        <TimeList eventId={eventId} dataKey="exhibits" headerText="Exhibit Hall Hours" refetch={refetch} />
      );
    default:
      return null;
  }
};

export default DynamicInformation;
