import React, { useEffect, useState } from "react";

interface AppSchemaResponse {
  entry: {
    text: string;
  };
}

const appSchema = 2;

const UpdateNotification: React.FC = () => {
  const [isSchemaOutdated, setIsSchemaOutdated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSchemaText = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/api/v2/app-schema`);
        if (!response.ok) {
          throw new Error("Failed to fetch schema");
        }

        const result: AppSchemaResponse = await response.json();
        const fetchedSchema = parseInt(result.entry.text, 10);

        if (fetchedSchema > appSchema) {
          setIsSchemaOutdated(true);
        }
      } catch (err: unknown) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchSchemaText();
  }, []);



  if (loading) {
    return null;
  }

  if (error) {
    return null;
  }

  if (isSchemaOutdated) {
    return (
      <div className="bg-opacity-80 flex w-full h-full absolute z-50 bg-black text-white align-middle justify-center items-center">
        <div>
          <h1 className="text-xl font-bold">Update Required</h1>
          <p>Please update the app to the latest version.</p>
        </div>
      </div>
    );
  }

  return null;
};

export default UpdateNotification;
