import React from "react";
import useFetch from "../../hooks/useFetch";
import Headline from "../atoms/Headline";
import ContentWrapper from "../molecules/ContentWrapper";
import Subhead from "../atoms/Subhead";
import DateString from "../utilities/DateString";
import timeString from "../utilities/TimeString";
import Button from "../atoms/Button";
import {useParams} from "react-router-dom";
import {IonPage} from "@ionic/react";
import EventHeader from "./EventHeader";

interface MatchParams {
  eventId: string;
}

interface Entry {
  id: string;
  meals?: Meal[];
}

interface Meal {
  id: string;
  title: string;
  startDate: string;
  startTime: string;
  endTime: string;
  venue: string;
}

const Meals: React.FC = () => {
  const { eventId } = useParams<MatchParams>();
  const { data, loading, error, refetch, isConnected } = useFetch<{ entry: Entry }>(
    `${process.env.REACT_APP_API_DOMAIN}/api/v2/app-event?id=${eventId}`
  );

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  let currentTime: string = "";
  let showTime: boolean = false;

  return (
    <IonPage>
      <EventHeader eventId={eventId}/>
      <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>

        <Headline title="Meals & Receptions" icon="Utensils" />
        {data?.entry.meals?.map((meal: Meal) => {

          let mealTime = meal.startDate + meal.startTime;

          if (currentTime !== mealTime) {
            currentTime = mealTime;
            showTime = true    ;
          } else {
            showTime = false;
          }

          return (
            <div key={meal.id}>
              {showTime && (
                <>
                  <span className="block h-6"></span>
                  <Subhead
                    title={DateString(meal.startDate)}
                    tag={timeString(meal.startTime, meal.endTime)}
                  />
                </>
              )}
              <Button
                to={`/events/${eventId}/details/mealReception/${meal.id}`}
                text={meal.title}
                tag={meal.venue}
              />
            </div>
          );
        })}
      </ContentWrapper>
    </IonPage>

  );
};

export default Meals;
