import {IonRouterOutlet} from "@ionic/react";
import {Route} from "react-router-dom";
import React from "react";
import Article from "./Article";
import News from "./News";

export default function NewsRouter() {
  return(
    <IonRouterOutlet>
      <Route exact path="/news/" component={News} />
      <Route exact path="/news/:articleId" component={Article} />
    </IonRouterOutlet>
  )
}
