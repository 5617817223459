import React from "react";
import useFetch from '../../hooks/useFetch';

import Headline from "../atoms/Headline";
import TextBlock from "../atoms/TextBlock";
import Subhead from "../atoms/Subhead";

import ContentWrapper from "../molecules/ContentWrapper";

import dateString from "../utilities/DateString";
import timeString from "../utilities/TimeString";
import TransparentBg from "../molecules/TransparentBg";
import {IonPage} from "@ionic/react";
import EventHeader from "./EventHeader";

interface Props {
  match: {
    params: {
      entryId: string;
      eventId: string;
    };
  };
}

const Workshop: React.FC<Props> = ({ match }) => {

  const { entryId, eventId } = match.params;

  const { data, loading, error, refetch, isConnected } = useFetch<{ entry: any }>(
    `${process.env.REACT_APP_API_DOMAIN}/api/v2/app-workshop?id=${entryId}`
  );

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  let html = { __html: data?.entry?.descriptionRichText };

  return (
    <IonPage>
      <EventHeader eventId={eventId}/>
      <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
        {data && (
          <>
            <Headline icon="ScreenUsers" title={data.entry.title} />

            <Subhead
              title={dateString(data.entry.parent?.parent?.startDate, "short")}
              tag={timeString(data.entry.parent?.startTime, data.entry.parent?.endTime)}
            />

            <TransparentBg>
              <TextBlock text={html} />
              <div className="text-block">
                <p>
                  Presented by {data.entry.shortname}
                </p>
                {data.entry.venue && (
                  <p>Location: {data.entry.venue}</p>
                )}
              </div>
            </TransparentBg>
          </>
        )}

      </ContentWrapper>
    </IonPage>

  );
};

export default Workshop;
