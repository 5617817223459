import React from "react";
import useFetch from "../../hooks/useFetch";
import ContentWrapper from "../molecules/ContentWrapper";
import Headline from "../atoms/Headline";
import Button from "../atoms/Button";
import Subhead from "../atoms/Subhead";
import TextBlock from "../atoms/TextBlock";
import Image from "../atoms/Image";
import DynamicInformation from "../atoms/DynamicInformation";
import TransparentBg from "../molecules/TransparentBg";
import * as icons from '../icons';
import {IonPage} from "@ionic/react";
import EventHeader from "./EventHeader";

interface Entry {
  id: string;
  typeHandle: string;
  mobilePageContent: Block[];
}

interface Block {
  id: string;
  typeHandle: string;
  icon?: keyof typeof icons;
  label: string;
  to?: string;
  text?: string | undefined;
  image?: { url: string };
  alt?: string;
  website?: string;
  options?: string | undefined;
  entry?: Entry;
  tag?: string;
}

interface MobilePageContentProps {
  blocks: Block[];
  eventId: string;
  refetch: () => void;
}

interface DetailsProps {
  match: {
    params: {
      entryId: string;
      eventId: string;
    };
  };
}

// MobilePageContent component
const MobilePageContent: React.FC<MobilePageContentProps> = ({ blocks, eventId, refetch }) => {
  return (
    <>
      {blocks.map((block: Block) => {
        switch (block.typeHandle) {
          case "headline":
            return <Headline title={block.label} key={block.id} icon={block.icon} />;
          case "subhead":
            return <Subhead title={block.label} key={block.id} />;
          case "text":
            return (
              <TransparentBg key={block.id}>
                <TextBlock text={{ __html: block.text || "" }} />
              </TransparentBg>
            );
          case "button":
            console.log(block);
            if (block.entry && block.entry.id) {
              console.log('yes');
              return (
                <Button
                  text={block.text}
                  icon={block.icon}
                  to={`/events/${eventId}/details/${block.entry.typeHandle}/${block.entry.id}`}
                  key={block.id}
                />
              );
            } else {
              console.log('no');
              return (
                <Button
                  text={block.text}
                  icon={block.icon}
                  to={`/events/${eventId}/details/${block.to}`}
                  key={block.id}
                />
              );
            }
          case "image":
            if (block.image) {
              return (
                <Image url={block.image.url} alt={block.alt} key={block.id} />
              );
            }
            return null;
          case "browserLink":
            return (
              <Button
                text={block.text}
                tag={block.tag}
                icon={block.icon}
                key={block.id}
                openInBrowser={block.website}
              />
            );
          case "dynamicInformation":
            return <DynamicInformation key={block.id} option={block.options} eventId={eventId} refetch={refetch} />;
          default:
            return null;
        }
      })}
    </>
  );
};

// Details component
const Details: React.FC<DetailsProps> = (props) => {
  const { entryId, eventId } = props.match.params;
  const { data, loading, error, refetch, isConnected } = useFetch<{ entry: Entry }>(
    `${process.env.REACT_APP_API_DOMAIN}/api/v2/app-details?id=${entryId}`
  );
  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  return (
    <IonPage>
      <EventHeader eventId={eventId}/>
      <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
        {data && (
          <MobilePageContent eventId={eventId} blocks={data.entry.mobilePageContent} refetch={refetch} />
        )}
      </ContentWrapper>
    </IonPage>
  );
};

export default Details;
