import React, { useEffect, useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Headline from "../atoms/Headline";
import Subhead from "../atoms/Subhead";
import TextBlock from "../atoms/TextBlock";
import Image from "../atoms/Image";
import DynamicInformation from "../atoms/DynamicInformation";
import Button from "../atoms/Button";
import TransparentBg from "../molecules/TransparentBg";
import { IonButton, IonButtons, IonHeader, IonPage, IonToolbar } from "@ionic/react";
import TopNavButton from "../atoms/TopNavButton";
import ContentWrapper from "../molecules/ContentWrapper";
import * as icons from "../icons";

interface MatchParams {
  eventId: string;
}

interface EventLandingProps extends RouteComponentProps<MatchParams> {}

interface Entry {
  id: string;
  typeHandle: string;
  primary?: string;
  image?: { url: string };
  title: string;
  locationOther: string;
  mobilePageContent: Block[];
}

interface Block {
  id: string;
  typeHandle: string;
  icon?: keyof typeof icons;
  label: string;
  to?: string;
  text?: string | undefined;
  image?: { url: string }[];
  alt?: string;
  website?: string;
  options?: string | undefined;
  entry?: Entry[];
  tag?: string;
}

interface MobilePageContentProps {
  blocks: Block[];
  eventId: string;
}

const MobilePageContent: React.FC<MobilePageContentProps> = ({ blocks, eventId }) => {
  return (
    <>
      {blocks.map((block: Block) => {
        switch (block.typeHandle) {
          case "headline":
            return <Headline title={block.label} key={block.id} icon={block.icon} />;
          case "subhead":
            return <Subhead title={block.label} key={block.id} />;
          case "text":
            return (
              <div key={block.id}>
                <TransparentBg>
                  <TextBlock text={{ __html: block.text || "" }} />
                </TransparentBg>
              </div>
            );
          case "button":
            if (block.entry && block.entry.length > 0 && block.entry[0].id) {
              return (
                <Button
                  text={block.text}
                  icon={block.icon}
                  to={`/events/${eventId}/details/${block.entry[0].typeHandle}/${block.entry[0].id}`}
                  key={block.id}
                />
              );
            }
            return (
              <Button
                text={block.text}
                icon={block.icon}
                to={`/events/${eventId}/details/${block.to}`}
                key={block.id}
              />
            );
          case "image":
            if (block.image && block.image.length > 0) {
              return (
                <Image
                  url={block.image[0].url}
                  alt={block.alt}
                  key={block.id}
                />
              );
            }
            return null;
          case "browserLink":
            return (
              <Button
                text={block.text}
                tag={block.tag}
                icon={block.icon}
                key={block.id}
                openInBrowser={block.website}
              />
            );
          case "dynamicInformation":
            return (
              <div key={block.id}>
                <DynamicInformation option={block.options} eventId={eventId} />
              </div>
            );
          default:
            return null;
        }
      })}
    </>
  );
};

const EventLanding: React.FC<EventLandingProps> = ({ match }) => {
  const eventId = match.params.eventId;
  const { data, loading, error, refetch, isConnected } = useFetch<{ entry: Entry }>(
    `${process.env.REACT_APP_API_DOMAIN}/api/v2/app-event?id=${eventId}`
  );

  const handleRefresh = async (event: CustomEvent) => {
    await refetch(); // Bypass cache to get fresh data
    event.detail.complete(); // Signal completion of the refresher
  };

  const style = useMemo(() => {
    if (data && data.entry && data.entry.primary) {
      const color = data.entry.primary;
      const colorR = color.slice(1, 3);
      const colorG = color.slice(3, 5);
      const colorB = color.slice(5, 7);

      return {
        '--color-primary': `${parseInt(colorR, 16)} ${parseInt(colorG, 16)} ${parseInt(colorB, 16)}`
      };
    }
    return {};
  }, [data]);

  useEffect(() => {
    if (style['--color-primary']) {
      document.documentElement.style.setProperty('--color-primary', style['--color-primary']);
    }
  }, [style]);

  return (
    <IonPage>
      <IonHeader translucent className="ion-no-border">
        <IonToolbar style={{ "--background": "var(--ion-tab-bar-background-color)" }} className="bg-opacity-50">
          <IonButtons slot="start">
            <IonButton>
              <TopNavButton icon="ChevronLeft" route='/events' />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
        {data && (
          <>
            {data.entry?.image && (
              <div className="bg-neutral-500 overflow-hidden -mx-6 -mt-6 mb-5 md:rounded-lg aspect-video bg-opacity-20">
                <img
                  src={data.entry.image.url}
                  className="block h-full w-full aspect-video"
                  alt=""
                />
              </div>
            )}
            {data.entry?.title && data.entry?.mobilePageContent && (
              <>
                <Headline title={data.entry.title} tag={data.entry.locationOther} />
                <MobilePageContent eventId={eventId} blocks={data.entry.mobilePageContent} />
              </>
            )}
          </>
        )}
      </ContentWrapper>
    </IonPage>
  );
};

export default EventLanding;
